import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardStatusPipe } from './card/card-status.pipe';
import { CardTypePipe } from './card/card-type.pipe';
import { IntegerToCurrencyPipe } from './currency/integer-to-currency.pipe';
import { GenderPipe } from './gender/gender.pipe';
import { MerchantTypeFilterPipe } from './merchant/merchant-type-filter.pipe';
import { MerchantTypePipe } from './merchant/merchant-type.pipe';
import { SanitizeUrlPipe } from './sanitizeUrl/sanitize-url.pipe';
import { TransactionResultPipe } from './transaction/transaction-result.pipe';
import { TransactionTypePipe } from './transaction/transaction-type.pipe';

@NgModule({
  declarations: [
    CardStatusPipe,
    CardTypePipe,
    GenderPipe,
    IntegerToCurrencyPipe,
    MerchantTypeFilterPipe,
    MerchantTypePipe,
    SanitizeUrlPipe,
    TransactionResultPipe,
    TransactionTypePipe,
  ],
  imports: [CommonModule],
  exports: [
    CardStatusPipe,
    CardTypePipe,
    GenderPipe,
    IntegerToCurrencyPipe,
    MerchantTypeFilterPipe,
    MerchantTypePipe,
    SanitizeUrlPipe,
    TransactionResultPipe,
    TransactionTypePipe,
  ],
})
export class PipesModule {}
