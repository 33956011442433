export const PostalCodeArray = [
  '15001',
  '15002',
  '15003',
  '15004',
  '15005',
  '15006',
  '15007',
  '15008',
  '15009',
  '15010',
  '15011',
  '15070',
  '15071',
  '15080',
  '15190',
  '15191',
  '15405',
];

export const GaliciaProvincesArray = [
  'A Coruña',
  'Lugo',
  'Orense',
  'Pontevedra',
];
