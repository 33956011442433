export const ImagesConfig = {
  toolbar: `https://presco.s3.eu-central-1.amazonaws.com/logo/logo_b_presco_transparent.png`,
  footerOwner: `https://presco.s3.eu-central-1.amazonaws.com/logo/logo_concello_footer.jpg`,
  footerOwnerAlt: `Concello de A Coruña`,
  footerPartner: `https://presco.s3.eu-central-1.amazonaws.com/logo/logo_abanca_blanco_fit.png`,
  footerPartnerAlt: `Abanca`,
  spinner: `https://presco.s3.eu-central-1.amazonaws.com/logo/PRESCO_logo_loading_blue.png`,
  emptyDefault: `https://presco.s3.eu-central-1.amazonaws.com/logo/image-default.png`,
  backgroundSupport: `https://presco.s3.eu-central-1.amazonaws.com/images/backgroud_support.svg`,
  backgroundSupportAlt: `Soporte`,
};
