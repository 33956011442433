import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CardStatusEnum, CardTypeEnum } from '@qaroni-core/entities';
import { qaroniEnterFadeInTrigger } from '@qaroni-shared/animations';
import * as moment from 'moment';

@Component({
  selector: 'qaroni-card-disabled-preview',
  templateUrl: './card-disabled-preview.component.html',
  styleUrls: ['./card-disabled-preview.component.scss'],
  animations: [qaroniEnterFadeInTrigger],
})
export class CardDisabledPreviewComponent implements OnInit {
  @Input() cardType: CardTypeEnum;
  @Input() disabled = false;

  @Output() create: EventEmitter<CardTypeEnum> = new EventEmitter();

  public expired = moment().add(2, 'months').format();

  constructor() {}

  ngOnInit(): void {}

  get cardButtonsColor(): string {
    if (this.cardType === CardTypeEnum.CULTURE) {
      return 'culture';
    } else if (this.cardType === CardTypeEnum.HOSTELRY) {
      return 'hostelry';
    } else if (this.cardType === CardTypeEnum.SHOP) {
      return 'shop';
    } else if (this.cardType === CardTypeEnum.SALON) {
      return 'salon';
    } else if (this.cardType === CardTypeEnum.TAXI) {
      return 'taxi';
    }
  }

  get cardBgColor(): string {
    if (this.cardType === CardTypeEnum.CULTURE) {
      return 'card-bg-culture';
    } else if (this.cardType === CardTypeEnum.HOSTELRY) {
      return 'card-bg-hostelry';
    } else if (this.cardType === CardTypeEnum.SHOP) {
      return 'card-bg-shop';
    } else if (this.cardType === CardTypeEnum.SALON) {
      return 'card-bg-salon';
    } else if (this.cardType === CardTypeEnum.TAXI) {
      return 'card-bg-taxi';
    }
  }

  get cardStatus(): CardStatusEnum {
    return CardStatusEnum.INACTIVE;
  }

  get cardBalance(): number {
    if (this.cardType === CardTypeEnum.CULTURE) {
      return 6000;
    } else if (this.cardType === CardTypeEnum.HOSTELRY) {
      return 1500;
    } else if (this.cardType === CardTypeEnum.SHOP) {
      return 3000;
    } else if (this.cardType === CardTypeEnum.SALON) {
      return 2000;
    } else if (this.cardType === CardTypeEnum.TAXI) {
      return 1000;
    }
  }

  public createCard(): void {
    this.create.emit(this.cardType);
  }
}
