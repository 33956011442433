<div class="col-12 mt-5">
  <div class="mat-headline">
    {{ "¿Necesitas ayuda adicional?" | translate }}
  </div>
  <p class="lead">
    {{ "Echa un vistazo a" | translate }}
    <a class="qaroni-link" [routerLink]="['/help-video']">{{
      "este vídeo" | translate
    }}</a
    >, {{ "quizás puede resolver tus dudas" | translate }}.
  </p>
</div>
