import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiQBonoEnv } from '@qaroni-core/config';
import { Observable } from 'rxjs';
import { CreateCardJson } from '../types/card';

@Injectable({
  providedIn: 'root',
})
export class CardHttpService {
  constructor(private http: HttpClient) {}

  public getCards$(
    clientID: number | string,
    params?: Params
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/clients/${clientID}/cards`;

    // const params: Params = Object.assign({}, queryParams);

    return this.http.get(url, { observe: 'response', params });
  }

  public createCard$(
    clientID: number | string,
    createCardJSON: CreateCardJson
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/clients/${clientID}/cards`;

    return this.http.post(url, createCardJSON, { observe: 'response' });
  }

  public getCard$(
    clientID: number | string,
    cardID: number | string,
    params?: Params
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/clients/${clientID}/cards/${cardID}`;

    return this.http.get(url, { observe: 'response', params });
  }

  public buildDownloadAppleWalletUrl(
    clientID: number | string,
    cardID: number | string,
    wallet: string
  ): string {
    const url = `${ApiQBonoEnv.baseUrl}/clients/${clientID}/cards/${cardID}/downloads?wallet=${wallet}`;

    return url;
  }

  public buildDownloadAndroidUrl(
    clientID: number | string,
    cardID: number | string,
    wallet: string
  ): string {
    const url = `${ApiQBonoEnv.baseUrl}/clients/${clientID}/cards/${cardID}/downloads?wallet=${wallet}&application=ANDROID`;

    return url;
  }

  public buildDownloadPdfUrl(
    clientID: number | string,
    cardID: number | string,
    wallet: string
  ): string {
    const url = `${ApiQBonoEnv.baseUrl}/clients/${clientID}/cards/${cardID}/pdf?wallet=${wallet}`;

    return url;
  }

  public buildPreviewPdfUrl(
    clientID: number | string,
    cardID: number | string,
    wallet: string
  ): string {
    const url = `${ApiQBonoEnv.baseUrl}/clients/${clientID}/cards/${cardID}/pdf/previews?wallet=${wallet}`;

    return url;
  }
}
