import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarConfig } from '@qaroni-core/config';

export const CardsSnackbars = {
  failureCreateCardLimit: {
    message: `No se puede crear la tarjeta bono, se alcanzó el límite del Programa Bonos Presco`,
    closeBtn: SnackbarConfig.strings.close,
    config: {
      panelClass: SnackbarConfig.classes.danger,
      duration: SnackbarConfig.durations.danger,
    } as MatSnackBarConfig,
  },
};
