<mat-card @enterFadeIn class="text-disabled mx-auto" [ngClass]="cardBgColor">
  <mat-card-content>
    <div class="row justify-content-between align-items-center">
      <div class="col-auto">
        <div class="mat-h3 mb-0">{{ cardType | cardType }}</div>
      </div>
      <div class="col-auto">
        <a
          class="mat-small qaroni-dark-link"
          [routerLink]="['/dashboard/merchants/list']"
          [queryParams]="{ type: cardType }"
          >{{ "Ver comercios" | translate }}</a
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12 px-3 mb-1">
        <div class="mat-small">
          {{ "Tarjeta bono" | translate }}
          {{ cardStatus | cardStatus | lowercase }}
        </div>
      </div>
      <div class="col-12 mb-2">
        <div class="mat-display-1 mb-0 text-center">
          {{ cardBalance | integerToCurrency | currency }}
        </div>
      </div>
      <!-- <div class="col-12 mb-3 text-right">
        <div class="mat-small">
          <div>
            <span>{{ "Vence el" | translate }}: </span>
            <strong>{{ expired | date: "medium" }}</strong>
          </div>
          <div>
            {{
              "La tarjeta bono caducará dos mes luego de activarla" | translate
            }}
          </div>
        </div>
      </div> -->
      <div class="col-12">
        <button
          mat-flat-button
          [color]="cardButtonsColor"
          [disabled]="disabled"
          (click)="createCard()"
        >
          <mat-icon>add</mat-icon> {{ "Activar bono de" | translate }}
          {{ cardType | cardType | lowercase }}
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
