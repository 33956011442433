export const AppUrlsConfig = {
  walletPassesLogo: `https://presco.s3.eu-central-1.amazonaws.com/logo/wallet-passes-logo.png`,
  walletPassesLogoAlt: `Wallet Passes`,

  passTwoULogo: `https://presco.s3.eu-central-1.amazonaws.com/logo/pass-2u-logo.png`,
  passTwoULogoAlt: `Pass 2U`,

  googlePlayStoreIcon: `https://presco.s3.eu-central-1.amazonaws.com/logo/google-play.png`,
  googlePlayStoreIconAlt: `Google Play Store`,

  appWalletPassesOnGooglePlayStore: `https://play.google.com/store/apps/details?id=io.walletpasses.android`,

  appPassTwoUOnGooglePlayStore: `https://play.google.com/store/apps/details?id=com.passesalliance.wallet`,

  regulatoryBases: `https://presco.coruna.gal/wp-content/uploads/2021/08/19082021-BASES-UTILIZACION-BONOS-PRESCO-galego-2.pdf`,
};
