import { Component, Input, OnInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Card, CardService, CardTypeEnum } from '@qaroni-core/entities';
import { AllAppService } from '@qaroni-core/services';
import { qaroniEnterFadeInTrigger } from '@qaroni-shared/animations';
import { DownloadAndroidDialogComponent } from '../dialogs/download-android-dialog/download-android-dialog.component';

@Component({
  selector: 'qaroni-card-preview',
  templateUrl: './card-preview.component.html',
  styleUrls: ['./card-preview.component.scss'],
  animations: [qaroniEnterFadeInTrigger],
})
export class CardPreviewComponent implements OnInit {
  @Input() card: Card;
  @Input() disabled = false;

  constructor(
    private allApp: AllAppService,
    private cardService: CardService
  ) {}

  ngOnInit(): void {}

  public getWalletButtonColor(card: Card): string {
    if (card) {
      if (card?.type === CardTypeEnum.CULTURE) {
        return 'culture';
      }
      if (card?.type === CardTypeEnum.HOSTELRY) {
        return 'hostelry';
      }
      if (card?.type === CardTypeEnum.SHOP) {
        return 'shop';
      }
      if (card?.type === CardTypeEnum.SALON) {
        return 'salon';
      }
      if (card?.type === CardTypeEnum.TAXI) {
        return 'taxi';
      }
    }
    return null;
  }

  public getCardBgColor(card: Card): string {
    if (card) {
      if (card?.type === CardTypeEnum.CULTURE) {
        return 'card-bg-culture';
      }
      if (card?.type === CardTypeEnum.HOSTELRY) {
        return 'card-bg-hostelry';
      }
      if (card?.type === CardTypeEnum.SHOP) {
        return 'card-bg-shop';
      }
      if (card?.type === CardTypeEnum.SALON) {
        return 'card-bg-salon';
      }
      if (card?.type === CardTypeEnum.TAXI) {
        return 'card-bg-taxi';
      }
    }
    return null;
  }

  public cardIsActive(card: Card): boolean {
    if (
      this.card?.type === CardTypeEnum.CULTURE ||
      this.card?.type === CardTypeEnum.HOSTELRY ||
      this.card?.type === CardTypeEnum.TAXI ||
      this.card?.type === CardTypeEnum.SHOP ||
      this.card?.type === CardTypeEnum.SALON
    ) {
      return true;
    }
    return this.cardService.isActive(card);
  }

  public downloadAppleWallet(): string {
    if (this.card?.cardId && this.card?.wallet) {
      return this.cardService.getDownloadAppleWallet(
        this.card?.cardId,
        this.card?.wallet
      );
    }
  }

  get downloadAndroid(): string {
    if (this.card?.cardId && this.card?.wallet) {
      return this.cardService.getDownloadAndroid(
        this.card?.cardId,
        this.card?.wallet
      );
    }
  }

  get downloadPdf(): string {
    if (this.card?.cardId && this.card?.wallet && this.allowPdf) {
      return this.cardService.getDownloadPdf(
        this.card?.cardId,
        this.card?.wallet
      );
    }
  }

  get allowPdf(): boolean {
    if (
      this.card?.type === CardTypeEnum.CULTURE ||
      this.card?.type === CardTypeEnum.HOSTELRY ||
      this.card?.type === CardTypeEnum.TAXI ||
      this.card?.type === CardTypeEnum.SHOP ||
      this.card?.type === CardTypeEnum.SALON
    ) {
      return true;
    }
    return this.cardService.isActive(this.card);
    // return this.cardService.isActive(this.card) && !!this.card?.isStatic;
  }

  public previewPdf(card: Card): string {
    if (this.card?.cardId && this.card?.wallet && this.allowPdf) {
      return this.cardService.getPreviewPdf(card?.cardId, this.card?.wallet);
    }
  }

  public showDownloadAndroidDialog(): void {
    if (this.card?.cardId) {
      const matDialogConfig = new MatDialogConfig();
      matDialogConfig.width = '700px';
      matDialogConfig.maxWidth = '90vw';
      matDialogConfig.panelClass = 'style-close-dialog';
      matDialogConfig.autoFocus = false;
      matDialogConfig.data = this.card;

      const dialog = this.allApp.dialog.open<
        DownloadAndroidDialogComponent,
        string,
        boolean
      >(DownloadAndroidDialogComponent, matDialogConfig);
    }
  }
}
