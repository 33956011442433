export enum CardTypeEnum {
  CULTURE = 'CULTURE',
  HOSTELRY = 'HOSTELRY',
  SHOP = 'SHOP',
  SALON = 'SALON',
  TAXI = 'TAXI',
}

export type CardType =
  | CardTypeEnum.CULTURE
  | CardTypeEnum.HOSTELRY
  | CardTypeEnum.SHOP
  | CardTypeEnum.SALON
  | CardTypeEnum.TAXI;

export const CardTypeArray = [
  CardTypeEnum.CULTURE,
  CardTypeEnum.HOSTELRY,
  CardTypeEnum.SHOP,
  CardTypeEnum.SALON,
  CardTypeEnum.TAXI,
];

export const CardTypeInfo = [
  {
    [CardTypeEnum.CULTURE]: {
      name: 'Cultura',
    },
  },
  {
    [CardTypeEnum.HOSTELRY]: {
      name: 'Hostelería',
    },
  },
  {
    [CardTypeEnum.SHOP]: {
      name: 'Comercio',
    },
  },
  {
    [CardTypeEnum.SALON]: {
      name: 'Estética',
    },
  },
  {
    [CardTypeEnum.TAXI]: {
      name: 'Taxi',
    },
  },
];
