export enum MerchantTypeEnum {
  CULTURE = 'CULTURE',
  HOSTELRY = 'HOSTELRY',
  SHOP = 'SHOP',
  SALON = 'SALON',
  TAXI = 'TAXI',
}

export type MerchantType =
  | MerchantTypeEnum.CULTURE
  | MerchantTypeEnum.HOSTELRY
  | MerchantTypeEnum.SHOP
  | MerchantTypeEnum.SALON
  | MerchantTypeEnum.TAXI;

export const MerchantTypeArray = [
  MerchantTypeEnum.CULTURE,
  MerchantTypeEnum.HOSTELRY,
  MerchantTypeEnum.SHOP,
  MerchantTypeEnum.SALON,
  MerchantTypeEnum.TAXI,
];

export const MerchantTypeInfo = [
  {
    [MerchantTypeEnum.CULTURE]: {
      name: 'Cultura',
    },
  },
  {
    [MerchantTypeEnum.HOSTELRY]: {
      name: 'Hostelería',
    },
  },
  {
    [MerchantTypeEnum.SHOP]: {
      name: 'Comercio',
    },
  },
  {
    [MerchantTypeEnum.SALON]: {
      name: 'Estética',
    },
  },
  {
    [MerchantTypeEnum.TAXI]: {
      name: 'Taxi',
    },
  },
];

export enum MerchantTypeToFilterEnum {
  ALL = 'ALL',
  CULTURE = 'CULTURE',
  HOSTELRY = 'HOSTELRY',
  SHOP = 'SHOP',
  SALON = 'SALON',
  TAXI = 'TAXI',
}

export const MerchantTypeToFilterArray = [
  MerchantTypeToFilterEnum.ALL,
  MerchantTypeToFilterEnum.CULTURE,
  MerchantTypeToFilterEnum.HOSTELRY,
  // MerchantTypeToFilterEnum.SHOP,
  // MerchantTypeToFilterEnum.SALON,
  MerchantTypeToFilterEnum.TAXI,
];

export const MerchantTypeToFilterInfo = [
  {
    [MerchantTypeToFilterEnum.ALL]: {
      name: 'Todos',
    },
  },
  {
    [MerchantTypeToFilterEnum.CULTURE]: {
      name: 'Cultura',
    },
  },
  {
    [MerchantTypeToFilterEnum.HOSTELRY]: {
      name: 'Hostelería',
    },
  },
  {
    [MerchantTypeToFilterEnum.SHOP]: {
      name: 'Comercio',
    },
  },
  {
    [MerchantTypeToFilterEnum.SALON]: {
      name: 'Estética',
    },
  },
  {
    [MerchantTypeToFilterEnum.TAXI]: {
      name: 'Taxi',
    },
  },
];
