import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiQBonoEnv } from '@qaroni-core/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MerchantHttpService {
  constructor(private http: HttpClient) {}

  public getMerchants$(params?: Params): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants`;

    return this.http.get(url, { observe: 'response', params });
  }

  public getMerchant$(
    merchantID: number | string
  ): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/${merchantID}`;

    return this.http.get(url, { observe: 'response' });
  }

  public getMerchantsMap$(params?: Params): Observable<HttpResponse<any>> {
    const url = `${ApiQBonoEnv.baseUrl}/merchants/maps`;

    return this.http.get(url, { observe: 'response', params });
  }
}
