import { Pipe, PipeTransform } from '@angular/core';
import {
  MerchantTypeToFilterEnum,
  MerchantTypeToFilterInfo
} from '@qaroni-app/application/merchants';

@Pipe({
  name: 'merchantTypeFilter',
})
export class MerchantTypeFilterPipe implements PipeTransform {
  transform(value: MerchantTypeToFilterEnum): string {
    if (value) {
      const key = value.trim().toUpperCase();
      for (const iterator of MerchantTypeToFilterInfo) {
        if (Object.keys(iterator)[0] === key) {
          return iterator[key].name;
        }
      }
    }
    return null;
  }
}
