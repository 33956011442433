<mat-card @enterFadeIn class="h-100 mx-auto" [ngClass]="getCardBgColor(card)">
  <mat-card-content>
    <div class="row justify-content-between align-items-center">
      <div class="col-auto">
        <div class="mat-h3 mb-0">{{ card?.type | cardType }}</div>
      </div>
      <div class="col-auto">
        <a
          class="mat-small qaroni-dark-link"
          [routerLink]="['/dashboard/merchants/list']"
          [queryParams]="{ type: card?.type }"
          >{{ "Ver comercios" | translate }}</a
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12 px-3 mb-1">
        <div class="mat-small">
          {{ "Tarjeta bono" | translate }} {{ card?.status | cardStatus }}
        </div>
      </div>
      <div class="col-12 mb-2">
        <div class="mat-display-1 mb-0 text-center">
          {{ card?.balance | integerToCurrency | currency }}
        </div>
      </div>
      <div class="col-12 mb-3 text-right">
        <div class="mat-small">
          <span>{{ "Vence el" | translate }}: </span>
          <strong>{{ card?.expirationDate | date: "medium" }}</strong>
        </div>
      </div>
      <div class="col-12">
        <a
          [href]="downloadAppleWallet(card)"
          download
          target="_blank"
          *ngIf="cardIsActive(card)"
        >
          <button
            mat-flat-button
            [color]="getWalletButtonColor(card)"
            type="button"
            class="mb-2 btn-block"
            [disabled]="disabled"
          >
            <mat-icon>phone_iphone</mat-icon>
            {{ "Descargar en Apple Wallet" | translate }}
          </button>
        </a>

        <a (click)="showDownloadAndroidDialog()" *ngIf="cardIsActive(card)">
          <button
            mat-flat-button
            [color]="getWalletButtonColor(card)"
            type="button"
            class="mb-2 btn-block"
            [disabled]="disabled"
          >
            <mat-icon>android</mat-icon>
            {{ "Descargar en Android" | translate }}
          </button>
        </a>

        <a
          mat-flat-button
          [color]="getWalletButtonColor(card)"
          [href]="downloadPdf"
          target="_blank"
          class="text-decoration-none btn-block"
          [disabled]="disabled"
          download
          *ngIf="allowPdf"
        >
          <mat-icon>picture_as_pdf</mat-icon>
          {{ "Descargar en PDF" | translate }}
        </a>

        <a
          [routerLink]="['/dashboard/cards', card?.cardId, 'transactions']"
          [queryParams]="{ cardType: card?.type }"
        >
          <button
            mat-button
            [color]="getWalletButtonColor(card)"
            type="button"
            class="mb-2 btn-block"
            [disabled]="disabled"
          >
            <mat-icon>list</mat-icon> {{ "Ver operaciones" | translate }}
          </button>
        </a>
      </div>
    </div>
  </mat-card-content>
</mat-card>
