<button mat-button type="button" class="close-icon" [mat-dialog-close]="false">
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-title>{{ "Descargar en Android" | translate }}</div>
<mat-dialog-content>
  <div class="row align-items-center">
    <div class="col-auto">
      <div class="mat-title mb-0">
        <strong>{{ "Paso 1" | translate }}:</strong>
      </div>
    </div>
    <div class="col">
      <p class="mb-0">
        {{ "Descargar App Wallet" | translate }}.
        {{ "Recomendamos" | translate }}:
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-6 mt-3 mb-5">
      <div
        class="d-flex flex-row justify-content-center align-items-center mb-2"
      >
        <img
          [src]="AppUrlsConfig.walletPassesLogo"
          [alt]="AppUrlsConfig.walletPassesLogoAlt"
          class="img-fluid mr-1 wallet-passes-img"
        />
        <p class="lead mb-0 text-truncate">Wallet Passes</p>
      </div>
      <div class="text-center">
        <a
          [href]="AppUrlsConfig.appWalletPassesOnGooglePlayStore"
          target="_blank"
        >
          <img
            [src]="AppUrlsConfig.googlePlayStoreIcon"
            [alt]="AppUrlsConfig.googlePlayStoreIconAlt"
            class="img-fluid play-store-img"
          />
        </a>
      </div>
    </div>
    <div class="col-6 mt-3 mb-5">
      <div
        class="d-flex flex-row justify-content-center align-items-center mb-2"
      >
        <img
          [src]="AppUrlsConfig.passTwoULogo"
          [alt]="AppUrlsConfig.passTwoULogoAlt"
          class="img-fluid mr-1 pass-2u-img"
        />
        <p class="lead mb-0 text-truncate">Pass 2U</p>
      </div>
      <div class="text-center">
        <a [href]="AppUrlsConfig.appPassTwoUOnGooglePlayStore" target="_blank">
          <img
            [src]="AppUrlsConfig.googlePlayStoreIcon"
            [alt]="AppUrlsConfig.googlePlayStoreIconAlt"
            class="img-fluid play-store-img"
          />
        </a>
      </div>
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col-auto">
      <div class="mat-title mb-0">
        <strong>{{ "Paso 2" | translate }}:</strong>
      </div>
    </div>
    <div class="col">
      <p class="mb-0">
        {{ "Haz clic en el botón para descargar el bono" | translate }}.
      </p>
    </div>
    <div class="w-100"></div>
    <div class="col-12 text-center mt-3 mb-4">
      <a
        mat-flat-button
        color="shop"
        [href]="downloadAndroid"
        target="_blank"
        class="text-decoration-none"
        download
        *ngIf="card?.cardId && card?.wallet"
      >
        <mat-icon>file_download</mat-icon>
        {{ "Descargar bono en Android" | translate }}
      </a>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="row justify-content-end align-items-center">
    <div class="col-auto">
      <button
        mat-flat-button
        color="accent"
        type="button"
        class="m-1"
        [mat-dialog-close]="true"
        cdkFocusInitial
      >
        {{ "Cerrar" | translate }}
      </button>
    </div>
  </div>
</mat-dialog-actions>
