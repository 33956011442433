import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { OAuthStorageService } from '@qaroni-app/application/auth';
import { AllAppService, CommonsHttpService } from '@qaroni-core/services';
import { PaginationLinks } from '@qaroni-core/types';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Merchant, MerchantLite } from '../types/merchant';
import { MerchantHttpService } from './merchant-http.service';

@Injectable({
  providedIn: 'root',
})
export class MerchantService {
  protected readonly merchantsSubject = new Subject<Merchant[]>();
  protected readonly merchantSubject = new Subject<Merchant>();
  protected readonly merchantsLiteSubject = new Subject<MerchantLite[]>();
  protected readonly paginationLinksSubject = new Subject<PaginationLinks>();

  constructor(
    private allApp: AllAppService,
    private merchantHttp: MerchantHttpService,
    private commonsHttp: CommonsHttpService,
    public oAuthStorage: OAuthStorageService
  ) {}

  private enableLoading(): void {
    this.allApp.progressBar.show();
  }

  private disableLoading(): void {
    this.allApp.progressBar.hide();
  }

  // ==========================================================================================
  // Get Merchants
  // ==========================================================================================

  public getMerchants$(): Observable<Merchant[]> {
    return this.merchantsSubject.asObservable();
  }

  public resetMerchants(): void {
    return this.merchantsSubject.next(null);
  }

  public getMerchants(params?: Params): void {
    this.enableLoading();
    this.merchantHttp
      .getMerchants$(params)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({
        next: this.nextGetMerchants,
        error: this.errorGetMerchants,
      });
  }

  private nextGetMerchants = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchants: Merchant[] = data.body.result;
      this.merchantsSubject.next(merchants);
      const paginationLinks: PaginationLinks = data.body.links;
      this.paginationLinksSubject.next(paginationLinks);
    } else {
      this.merchantsSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetMerchants = (error: HttpErrorResponse): void => {
    this.merchantsSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Get Merchant
  // ==========================================================================================

  public getMerchant$(): Observable<Merchant> {
    return this.merchantSubject.asObservable();
  }

  public getMerchant(merchantID: number | string): void {
    this.enableLoading();
    this.merchantHttp
      .getMerchant$(merchantID)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({ next: this.nextGetMerchant, error: this.errorGetMerchant });
  }

  private nextGetMerchant = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchant: Merchant = data.body.result[0];
      this.merchantSubject.next(merchant);
    } else {
      this.merchantSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetMerchant = (error: HttpErrorResponse): void => {
    this.merchantSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Get Merchants Map
  // ==========================================================================================

  public getMerchantsMap$(): Observable<MerchantLite[]> {
    return this.merchantsLiteSubject.asObservable();
  }

  public resetMerchantsMap(): void {
    return this.merchantsLiteSubject.next(null);
  }

  public getMerchantsMap(params?: Params): void {
    this.enableLoading();
    this.merchantHttp
      .getMerchantsMap$(params)
      .pipe(finalize(() => this.disableLoading()))
      .subscribe({
        next: this.nextGetMerchantsMap,
        error: this.errorGetMerchantsMap,
      });
  }

  private nextGetMerchantsMap = (data: HttpResponse<any>): void => {
    if (this.commonsHttp.validationsHttp.verifyStatus200(data)) {
      const merchants: MerchantLite[] = data.body.result;
      this.merchantsLiteSubject.next(merchants);
    } else {
      this.merchantsLiteSubject.next(null);
      this.commonsHttp.errorsHttp.apiInvalidResponse(data);
    }
  }

  private errorGetMerchantsMap = (error: HttpErrorResponse): void => {
    this.merchantsLiteSubject.next(null);
    this.commonsHttp.errorsHttp.communication(error);
  }

  // ==========================================================================================
  // Pagination Links
  // ==========================================================================================

  public getPaginationLinks$(): Observable<PaginationLinks> {
    return this.paginationLinksSubject.asObservable();
  }
}
